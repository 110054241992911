import { type ExperimentManager } from './ExperimentManager'
import { type Feature, type FeatureVariants } from './Experiments.consts'

const FORCED_VARIATION_SEARCH_PREFIX = 'FEATURE_'

export function forceVariantsFromURL(
  urlSearchParams: URLSearchParams,
  expManager: ExperimentManager
) {
  for (const [name, value] of urlSearchParams.entries()) {
    if (!name.startsWith(FORCED_VARIATION_SEARCH_PREFIX)) {
      continue
    }

    const experimentKey = name.substring(FORCED_VARIATION_SEARCH_PREFIX.length)
    expManager.forceVariant(
      experimentKey as Feature,
      value as FeatureVariants[Feature]
    )
  }
}
